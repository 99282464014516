import { extendTheme, defineStyleConfig } from "@chakra-ui/react";

export const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        bg: "gray.50",
      },
    }),
  },
  fonts: {
    heading: " -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
    body: "-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: "blue",
      },
      sizes: {
        xs: {
          borderRadius: "sm",
        },
        sm: {
          borderRadius: "base",
        },
      },
    },
    Link: {
      baseStyle: {
        color: "blue.500",
      },
    },
    VStack: {
      defaultProps: {
        width: "100%",
      },
    },
  },
});
