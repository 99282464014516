import NProgress from "nprogress";
import nProgressStyles from "nprogress/nprogress.css";
import { Analytics } from "@vercel/analytics/react";
import { Button, ChakraProvider, Container, Heading, Text, Link as ChakraLink, Code } from "@chakra-ui/react";
import { withEmotionCache } from "@emotion/react";
import { cssBundleHref } from "@remix-run/css-bundle";
import type { LinksFunction } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetchers,
  useLoaderData,
  useNavigation,
  useRevalidator,
  useRouteError } from
"@remix-run/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { theme } from "./styles/theme";
import { withSentry } from "@sentry/remix";

import { SupabaseClient, Session, createBrowserClient } from "@supabase/auth-helpers-remix";
import { json } from "@remix-run/node"; // change this import to whatever runtime you are using
import getSupabaseServerClient from "./lib/supabase.server";

import type { LoaderArgs } from "@remix-run/node"; // change this import to whatever runtime you are using
import { Database } from "./types/supabase-types";
import globalStyles from "./styles/global.css";
import { ModalProvider } from "./hooks/useModal";
import { HiOutlineHome } from "react-icons/hi2";
import { SUPPORT_EMAIL } from "./utils/constants";
import { useChatra } from "./hooks/useChatra";
import { ClientOnly } from "remix-utils";
import { createPortal } from "react-dom";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";

export type TypedSupabaseClient = SupabaseClient<Database>;
export type MaybeSession = Session | null;
export type SupabaseOutletContext = {
  supabase: TypedSupabaseClient;
  session: MaybeSession;
};

ReactGA.initialize("G-2HZ3WFXT9R");
mixpanel.init("a2906598941c5a59a01ef38f343004b1");

export const meta = () => {
  return [{ charset: "utf-8" }, { title: "CopyFrame" }, { viewport: "width=device-width,initial-scale=1" }, { name: "robots", content: "noindex" }];
};

export const links: LinksFunction = () => [
{ rel: "stylesheet", href: globalStyles, type: "text/css", as: "style" },
{ rel: "stylesheet", href: nProgressStyles, type: "text/css", as: "style" },
{ rel: "icon", href: "/favicon.png?v=2", type: "image/png" },
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : [])];


export const loader = async ({ request }: LoaderArgs) => {
  const env = {
    SUPABASE_URL: process.env.SUPABASE_URL!,
    SUPABASE_ANON_KEY: process.env.SUPABASE_ANON_KEY!
  };
  const response = new Response();
  const supabase = getSupabaseServerClient({ request, response });

  const {
    data: { session }
  } = await supabase.auth.getSession();

  return json(
    {
      env,
      session
    },
    {
      headers: response.headers
    }
  );
};

export function Head() {
  return (
    <>
      <Meta />
    </>);

}

function App() {
  const { env, session } = useLoaderData<typeof loader>();
  const { revalidate } = useRevalidator();

  const [supabase] = useState(() => createBrowserClient<Database>(env.SUPABASE_URL, env.SUPABASE_ANON_KEY));

  const serverAccessToken = session?.access_token;

  useEffect(() => {
    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event !== "INITIAL_SESSION" && session?.access_token !== serverAccessToken) {
        // server and client are out of sync.
        revalidate();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [serverAccessToken, supabase, revalidate]);

  let transition = useNavigation();

  let fetchers = useFetchers();

  /**
   * This gets the state of every fetcher active on the app and combine it with
   * the state of the global transition (Link and Form), then use them to
   * determine if the app is idle or if it's loading.
   * Here we consider both loading and submitting as loading.
   */
  let state = useMemo<"idle" | "loading">(
    function getGlobalState() {
      let states = [transition.state, ...fetchers.map((fetcher) => fetcher.state)];
      if (states.every((state) => state === "idle")) return "idle";
      return "loading";
    },
    [transition.state, fetchers]
  );

  useEffect(() => {
    // and when it's something else it means it's either submitting a form or
    // waiting for the loaders of the next location so we start it
    if (state === "loading") NProgress.start();
    // when the state is idle then we can to complete the progress bar
    if (state === "idle") NProgress.done();
  }, [transition.state]);

  useChatra();

  return (
    <>
      <Links />
      <ClientOnly>{() => createPortal(<Head />, document.head)}</ClientOnly>
      <ChakraProvider theme={theme}>
        <ModalProvider>
          <Outlet context={{ supabase }} />
        </ModalProvider>
      </ChakraProvider>
      <ScrollRestoration />
      <Scripts />
      <LiveReload />
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(env)}`
        }} />

    </>);

}

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <>
        <ChakraProvider theme={theme}>
          <Container py={16} textAlign="center" maxW="container.sm">
            <Heading as="h1" size="2xl" mb={4}>
              {error.status}
            </Heading>
            <Heading as="h2" size="lg" mb={4}>
              {error.statusText}
            </Heading>
            <Text>
              <Code>{error.data}</Code>
            </Text>
            <Button as={Link} to="/" leftIcon={<HiOutlineHome />} mt={6}>
              Back to Home
            </Button>
          </Container>
        </ChakraProvider>
      </>);

  } else if (error instanceof Error) {
    return (
      <>
        <ChakraProvider theme={theme}>
          <Container py={16} textAlign="center" maxW="container.sm">
            <Heading as="h1" size="2xl" mb={4}>
              Oops!
            </Heading>
            <Heading as="h2" size="lg" mb={4}>
              Something went wrong.
            </Heading>
            {/* <Text as="p" fontSize="md" align="center" mb={2}>
               Our team has been notified and will look into it.
              </Text> */}
            <Text fontWeight="semibold">
              Please <ChakraLink href={`mailto:${SUPPORT_EMAIL}`}>contact support</ChakraLink> if the problem persists.
            </Text>
            <Button as={Link} to="/" leftIcon={<HiOutlineHome />} mt={6} reloadDocument={true}>
              Back to Home
            </Button>
          </Container>
        </ChakraProvider>
      </>);

  } else {
    return <h1>Unknown Error</h1>;
  }
}

export default withSentry(App);