import { useEffect } from "react";

declare global {
  interface Window {
    ChatraID: string;
    Chatra: any;
    ChatraSetup: any;
  }
}

export const useChatra = () => {
  useEffect(() => {
    window.ChatraID = "22BFw8QRRexZMjEpf";
    const script = document.createElement("script");

    window.Chatra =
      window.Chatra ||
      function () {
        (window.Chatra.q = window.Chatra.q || []).push(arguments);
      };

    window.ChatraSetup = {
      colors: {
        buttonBg: "#3182ce",
      },
    };

    script.async = true;
    script.src = "https://call.chatra.io/chatra.js";

    document.head.appendChild(script);

    return () => {
      // Cleanup function to remove the script when component unmounts
      document.head.removeChild(script);
    };
  }, []);
};
